require("jquery");

$(document).on("turbolinks:load", function () {
  var App = function () {
    this.initFlashMessages();
    this.initListings();
    this.initSelectInputs();
  };

  App.prototype.initFlashMessages = function () {
    setTimeout(function () {
      $('.js-flash-message').addClass('is-hidden').one('transitionend', function () {
        $(this).remove();
      });
    }, 3000);
  };

  App.prototype.initListings = function () {
    $('.js-listing-row').on('click', function (e) {
      if ($(e.target).prop('tagName').toLowerCase() !== 'a') {
        Turbolinks.visit($(this).attr('data-href'));
      }
    });
  };

  App.prototype.initSelectInputs = function () {
    $('.js-select-input').each(function () {
      $(this).addClass('c-select__input').wrap('<div class="c-select"></div>');
    });
  };

  new App();
});

